<script setup lang="ts">
// vuetify
import { useTheme } from 'vuetify'

// composables
import { usePage } from '@/composables/page'

// stores
import { useGlobalStore } from '@/store/global'

/**
 * page metadata
 * ==================================================================
 */
definePageMeta({
  auth: false,
  name: 'LoginPage',
  layout: 'auth',
  middleware: ['validate-route-roles'],
  noAuthRequired: true
})

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()

/**
 * route
 * ==================================================================
 */
const route = useRoute()

/**
 * state
 * ==================================================================
 */
const title = ref('Login')
const titleIcon = ref('')
const isLoading = ref(true)

/**
 * composables
 * ==================================================================
 */
usePage(title)

/**
 * stores
 * ==================================================================
 */
const globalStore = useGlobalStore()
const { pageTitle, pageTitleIcon } = storeToRefs(globalStore)

/**
 * computed
 * ==================================================================
 */
const defaultUsername = computed(() => {
  return route.query.username
    ? String(route.query.username)
    : undefined
})

const defaultPassword = computed(() => {
  return route.query.password
    ? String(route.query.password)
    : undefined
})

const redirectUrl = computed(() => {
  return route.query.redirectUrl
    ? `/${String(route.query.redirectUrl)}`
    : undefined
})

/**
 * page setup
 * ==================================================================
 */
pageTitle.value = title.value
pageTitleIcon.value = titleIcon.value

/**
 * lifecycle hooks
 * ==================================================================
 */
onMounted(() => {
  isLoading.value = false
})
</script>

<template>
  <div
    :style="{ width: '75vw', height: '100%' }"
    class="px-2 px-md-0  d-flex flex-column align-center justify-center"
  >
    <v-card
      class="pa-5 mb-2"
      min-height="350px"
      width="min(100%, 700px)"
    >
      <div class="pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            class="d-flex justify-center"
          >
            <r-icon-brand
              :size="110"
              :color-top="vuetifyTheme.current.value.colors.primary"
            />
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <div class="text-center text-sm-start">
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-col cols="12">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="article,text@2,actions"
                  />
                  <client-only>
                    <lazy-r-form-login
                      :default-username="defaultUsername"
                      :default-password="defaultPassword"
                      :redirect-url="redirectUrl"
                    />
                  </client-only>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <div
      style="width: 700px; max-width: 75vw"
      class="d-flex justify-end py-0"
    >
      <v-btn
        variant="text"
        color="secondary"
        size="small"
        to="/secret"
      >
        Forgot username or password?
      </v-btn>
    </div>
  </div>
</template>
